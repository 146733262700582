.container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 60px;
}

.pageTitleContainer {
    margin-bottom: 50px;
}

.buttonContainer {
    margin-top: 40px;
}

.drawsContainer {
    margin-top: 100px;
}

.sectionTitleContainer {
    margin-bottom: 30px;
}