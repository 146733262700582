.sectionTitleContainer {
    text-align: center;
    margin-bottom: 16px;
}

.subheading {
    font-weight: 400;
    font-size: 14px;
    text-align: center;
    margin-bottom: 30px;
}

.row {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-bottom: 20px;
}

.cardContainer {
    margin-right: 25px;
}

.content {
    width: 15vw;
    height: 22vh;
    display: flex;
    flex-direction: column;
}

.title {
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 20px;
}

.body {
    font-size: 12px;
    font-weight: 400;
}