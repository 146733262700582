.sideBar {
    background: #110823;
}

.helpText {
    color: white;
}

.currentTab {
    background: linear-gradient(277.51deg, var(--blue-purple) 0.58%, #8D58FF 100%);
}

.tabText {
    color: white;
}

.currentTabText {
    color: white;
}