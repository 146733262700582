.plaidItemContainer {
    margin-top: 30px;
    margin-bottom: 30px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.institutionHeaderContainer {
    margin-bottom: 10px;
}

.plaidAccountRadioContainer {
    margin-top: 5px;
    margin-bottom: 5px;
}