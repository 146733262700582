.container {
    padding: 60px;
    display: flex;
    flex-direction: column;
}

.input {
    display: flex;
    flex-direction: column;
    margin-top: 10px;
    margin-bottom: 10px;
}

.sectionTitleContainer {
    margin-top: 40px;
}

.buttonContainer {
    margin-top: 40px;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.errorContainer {
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.error {
    color: #D32F2F;
    font-weight: 300;
    font-size: 15px;
}