.container {
    display: flex;
    flex-direction: column;
}

.mainTabContainer {
    height: 45px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;
}

.iconContainer {
    margin-left: 40px;
    margin-right: 10px;
    width: 15px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.label {
    font-size: 18px;
    font-weight: 400;
}

.lastSubtabContainer {
    margin-bottom: 5px;
}