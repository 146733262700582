.container {
    box-shadow: 0px 2px 8px 0px #0000001F;
    background-color: #FFFFFF;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    padding: 8px;
}

.month {
    font-size: 11px;
    font-weight: 600;
    color: #757575;
    margin-bottom: 5px;
}

.ledger {
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 10px;
}

.numbersContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-size: 18px;
    font-weight: 600;
}

.amount {
    margin-right: 30px;
}