.container {
    display: flex;
    flex-direction: row;
    padding: 60px;
}

.leftSectionContainer {
    flex: 2;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    margin-right: 60px;
}

.image {
    width: 70%;
    aspect-ratio: 1;
}

.rightSectionContainer {
    flex: 3;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.pageTitleContainer {
    margin-bottom: 30px;
}

.bulletContainer {
    margin-bottom: 30px;
}