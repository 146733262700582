.savingsCardsTitle {
    font-weight: 600;
    font-size: 16px;
    margin-bottom: 40px;
}

.row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 30px;
}

.content {
    width: 17vw;
    height: 25vh;
    display: flex;
    flex-direction: column;
}

.title {
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 20px;
}

.body {
    font-size: 12px;
    font-weight: 400;
}