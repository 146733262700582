.container {
    padding: 60px;
}

.nextButtonContainer {
    position: fixed;
    bottom: 20px;
    left: 50%;
}

.green {
    color: green;
}

.red {
    color: red;
}