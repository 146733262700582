.container {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.signupContainer {
    flex: 1;
}

.scheduleDemoContainer {
    margin-left: 15px;
}