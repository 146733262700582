.container {
    display: flex;
    flex-direction: column;
    padding: 60px;
    background: #EAE5F2;
}

.sectionTitleContainer {
    margin-bottom: 10px;
}

.subtitle {
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 30px;
}

.cardGrid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 16px;
}