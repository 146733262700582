.iframeContainer {
    width: 100%;
    height: 100%;
    position: relative;
}

.toggleIconContainer {
    position: absolute;
    top: 5px;
    left: 5px;
    z-index: 1;
    cursor: pointer;
}

.toggleIcon {
    height: 20px;
}