.container {
    display: flex;
    flex-direction: column;
    padding: 5px;
    background-color: #FAFAFA;
    border-radius: 12px;
}

.row {
    display: flex;
    flex-direction: row;
    margin-top: 5px;
    margin-bottom: 5px;
}

.dayButtonContainer {
    margin-left: 3px;
    margin-right: 3px;
}