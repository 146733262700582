.container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.tableContainer {
    margin-top: 30px;
    margin-bottom: 30px;
}

table {
    border-collapse: separate;
    border-spacing: 1em 2em;
}