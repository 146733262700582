.container {
    box-shadow: 0px 2px 8px 0px #0000001F;
    background-color: #FFFFFF;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    padding: 8px;
}

.month {
    font-size: 11px;
    font-weight: 400;
    margin-bottom: 5px;
    color: #757575;
}

.inflowsAndOutflowsContainer {
    display: flex;
    flex-direction: row;
    margin-bottom: 10px;
}

.netCashFlow {
    font-size: 11px;
    font-weight: 600;
    color: #F8CE39;
}

.number {
    font-size: 18px;
    font-weight: 600;
}

.inflowsContainer {
    margin-right: 20px;
}

.inflows {
    font-size: 11px;
    font-weight: 600;
    color: #8D58FF;
}

.outflows {
    font-size: 11px;
    font-weight: 600;
    color: #757575;
}
