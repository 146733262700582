.container {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #EAE5F2;
    height: 100vh;
    width: 100vw;
}

.logo {
    position: absolute;
    top: 20px;
    left: 20px;
    width: 15vw;
}

.pageTitleContainer {
    margin-bottom: 20px;
}

.subtitle {
    font-weight: 400;
    font-size: 16px;
    margin-bottom: 30px;
}



