.container {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-left: 30px;
    height: 60px;
}

.stateBadge {
    height: 30px;
    width: 30px;
    margin-right: 10px;
}