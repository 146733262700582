.titleContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 30px;
}

.totalAmoutSaved {
    font-weight: 600;
    font-size: 17px;
}

.number {
    margin-left: 20px;
    font-weight: 700;
    font-size: 30px;
}