.container {
    box-shadow: 0px 2px 8px 0px #0000001F;
    background-color: #FFFFFF;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    padding: 8px;
}

.month {
    font-size: 11px;
    font-weight: 600;
    color: #8D58FF;
}

.total {
    font-size: 18px;
    font-weight: 600;
}
