.title {
    font-size: 34px;
    font-weight: 600;
    margin-bottom: 6px;
}

.subtitle {
    font-size: 16px;
    font-weight: 500;
    color: #757575;
}