.sideBar {
    background-color: #F5E6E6;
}

.helpText {
    color: black;
}

.currentTab {
    background-color: #F05D5C;
}

.tabText {
    color: black;
}

.currentTabText {
    color: white
}