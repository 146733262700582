.sideBar {
    background-color: #edebfd;
}

.helpText {
    color: #2c2c70;
}

.currentTab {
    background-color: #2c2c70;
}

.tabText {
    color: #2c2c70;
}

.currentTabText {
    color: white
}