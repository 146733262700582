.container {
    display: flex;
    flex-direction: row;
    padding: 60px;
    background-color: #FCFBF7;
}

.leftSectionContainer {
    flex: 2;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.pageTitleContainer {
    margin-bottom: 30px;
}

.body {
    font-weight: 400;
    font-size: 14px;
    margin-bottom: 30px;
}

.rightSectionContainer {
    margin-left: 60px;
    position: relative;
    flex: 1;
}

.circle {
    width: 100%;
    aspect-ratio: 1;
    border-radius: 50%;
    background: linear-gradient(35.22deg, #CCE6F0 0%, #DBEAC3 100%);
}

.cashFlowGraphic {
    position: absolute;
    width: 60%;
    z-index: 1;
    top: 5%;
    left: 5%;
}

.netProfitGraphic {
    position: absolute;
    width: 60%;
    z-index: 2;
    top: 50%;
    left: 5%;
}

.topExpensesGraphic {
    position: absolute;
    width: 60%;
    z-index: 3;
    top: 25%;
    left: 50%;
}