.container {
    display: flex;
    flex-direction: column;
}

.graphic {
    width: 25vw;
}

.pageContainer {
    padding: 60px;
}

.tableContainer {
    margin-bottom: 60px;
}