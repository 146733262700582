.container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 60px;
}

.providerListContainer {
    margin-bottom: 30px;
}

.sectionTitleContainer {
    margin-bottom: 20px;
}

.row {
    cursor: pointer;
}