.container {
    padding: 60px;
}
  
.pageTitleContainer {
    margin-bottom: 40px;
    text-align: center;
}

.grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
}