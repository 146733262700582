.title {
    font-size: 15px;
    font-weight: 500;
    margin-bottom: 20px;
}

.children {
    margin-bottom: 20px;
    font-size: 15px;
    font-weight: 400;
}

.description {
    font-size: 13px;
    font-weight: 400;
}