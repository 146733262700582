.container {
    display: flex;
    flex-direction: column;
}

.input {
    border: 0;
    padding: 12px 16px 12px 16px;
    border-radius: 48px;
    font-size: 14px;
    font-weight: 400;
}

.error {
    color: red;
    margin-top: 5px;
    margin-left: 20px;
    font-size: 12px;
    font-weight: 400;
}