.container {
    border-width: 0.2px;
    border-style: solid;
    border-color: #77786A;
    padding: 10px;
    display: flex;
    flex-direction: column;
}

.headerContainer {
    display: flex;
    flex-direction: row;
}

.mask {
    margin-left: 30px;
}

.type {
    font-size: 14px;
    color: #77786A;
}