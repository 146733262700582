.container {
    display: flex;
    flex-direction: row;
}

.bullet {
    margin-top: 2px;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background: linear-gradient(41.15deg, var(--blue-purple) -11.71%, #8D58FF 105.76%);
    color: white;
    font-weight: 400;
    font-size: 12px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-right: 16px;
}

.description {
    flex: 1;
    font-weight: 400;
    font-size: 14px;
}