.sideBar {
    background-color: #275BA9;
}

.helpText {
    color: white;
}

.currentTab {
    background-color: #1CA76C;
}

.tabText {
    color: white;
}

.currentTabText {
    color: white
}