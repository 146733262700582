.container {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.institutionLogo {
    width: 40px;
    height: 40px;
    margin-right: 10px;
}

.institutionNameContainer {
    display: flex;
    flex-direction: column;
}

.institutionName {
    font-size: 18px;
    font-weight: 500;
}