.container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.tableContainer {
    margin-top: 30px;
    margin-bottom: 30px;
}

.tableTitleContainer {
    margin-bottom: 20px;
}

table {
    border-collapse: separate;
    border-spacing: 1em 2em;
}

.lockedRow {
    background-color: #31c4cf;
}