.container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 150px;
    padding: 10px;
    box-shadow: 0px 2px 8px 0px #0000001F;
    border-radius: 12px;
    background: #FFFFFF;
}

.arrowIconContainer {
    width: 20px;
    height: 20px;
    cursor: pointer;
    user-select: none;
}

.disabled {
    cursor: not-allowed;
}

.year {
    font-size: 16px;
    font-weight: 500;
    color: var(--blue-purple);
}