.container {
    height: 30vh;
    width: 25vw;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.body {
    margin-top: 20px;
    font-weight: 400;
    font-size: 15px;
    text-align: center;
}

.buttonContainer {
    margin-top: auto;
}