.categoryContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.categoryCircle {
    width: 36px;
    height: 36px;
    border-radius: 100px;
    margin-right: 10px;
}

.category {
    font-weight: 600;
    font-size: 14px;
    color: #757575;
}

.noWrap {
    white-space: nowrap;
}