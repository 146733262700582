.container {
    padding: 60px;
}

.backButtonContainer {
    position: fixed;
    top: 20px;
}

.nextButtonContainer {
    position: fixed;
    bottom: 20px;
    left: 50%;
}