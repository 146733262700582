.container {
    padding: 60px;
    background: #EAE5F2;
}

.titleSection {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px;
}

.pageTitleContainer {
    flex: 1;
    margin-right: 40px;
}

.subtitle {
    flex: 1;
    font-weight: 400;
    font-size: 16px;
}

.cardContainer {
    margin-bottom: 16px;
}

.openModal {
    cursor: pointer;
    color: var(--blue-purple);
}