.container {
    display: flex;
    flex-direction: column;
}

.lineContainer {
    height: 16px;
    background-color: #F2F2F2;
    border-radius: 8px;
    display: flex;
    flex-direction: row;
}

.outstandingBalanceLine {
    background-color: #CDE6F0;
    border-radius: 8px;
}

.labelsContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 16px;
}

.label {
    font-size: 11px;
    font-weight: 400;
    color: #757575;
}

.number {
    font-size: 19px;
    font-weight: 600;
}

.textAlignRight {
    text-align: right;
}