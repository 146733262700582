.container {
    display: flex;
    flex-direction: row;
    padding: 60px;
    background-color: #FCFBF7;
}

.leftSectionContainer {
    flex: 1;
}

.pageTitleContainer {
    margin-bottom: 30px;
}

.body {
    font-weight: 400;
    font-size: 14px;
    margin-bottom: 30px;
}

.rightSectionContainer {
    flex: 1;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

