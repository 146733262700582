.container {
    display: flex;
    flex-direction: column;
    padding: 60px;
}

.sectionTitleContainer {
    margin-bottom: 10px;
}

.subtitle {
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 20px;
}

.disclaimers {
    text-align: right;
    margin-top: 20px;
    font-size: 10px;
    font-weight: 400;
}