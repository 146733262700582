.container {
    display: flex;
    flex-direction: column;
}

.labelContainer {
    margin-bottom: 5px;
}

.text {
    font-weight: 600;
    font-size: 19px;
}