.container {
    display: flex;
    flex-direction: column;
    padding: 60px;
}

.sectionTitleContainer {
    margin-bottom: 10px;
}

.subtitle {
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 30px;
}

.statsContainer {
    width: 80%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
}