.container {
    display: flex;
    flex-direction: column;
    height: 15vh;
}

.title  {
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 10px;
}

.subtitle {
    font-size: 13px;
    font-weight: 400;
}