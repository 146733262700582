.container {
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: white;
    border-radius: 48px;
    padding: 4px;
}

.inputFieldContainer {
    flex: 1;
}