.sideBar {
    background-color: #EEE3FC;
}

.helpText {
    color: black;
}

.currentTab {
    background-color: #8F37FF;
}

.tabText {
    color: black
}

.currentTabText {
    color: white
}