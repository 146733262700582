.container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.subtitle {
    font-size: 16px;
    margin-top: 20px;
    margin-bottom: 40px;
}

.buttonContainer {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}