.container {
    display: flex;
    flex-direction: row;
    padding: 60px;
    background: linear-gradient(to top right, #E6F0F4 28%, #DBEAC3 90%)
}

.leftSectionContainer {
    flex: 5;
    display: flex;
    flex-direction: column;
}

.logosContainer {
    width: 50%;
    margin-bottom: 30px;
}

.pageTitleContainer {
    margin-bottom: 20px;
}

.body {
    font-weight: 400;
    font-size: 12px;
    margin-bottom: 40px;
}

.superscript {
    font-weight: 400;
    font-size: 8px;
}

.spacer {
    flex: 2;
}

.rightSectionContainer {
    flex: 5;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

