.container {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.itemContainer {
    margin-right: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.label {
    margin-left: 5px;
    font-size: 11px;
    font-weight: 600;
}

.inflows {
    color: #8D58FF;
}

.outflows {
    color: #757575;
}

.netCashFlow {
    color: #F8CE39;
}