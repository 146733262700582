.container {
    height: 35px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;
}

.label {
    margin-left: 80px;
    font-size: 14px;
    font-weight: 400;
}