.container {
    display: flex;
    flex-direction: column;
    background: #CDE6F0;
    padding: 60px;
    align-items: center;
}

.title {
    font-weight: 600;
    font-size: 25px;
    text-align: center;
    margin-bottom: 30px;
}

.body {
    margin-bottom: 30px;
    font-weight: 400;
    font-size: 14px;
    text-align: center;
}