.container {
    padding: 25px;
    display: flex;
    flex-direction: column;
}

.imageContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-bottom: 16px;
}

.image {
    width: 25px;
}

.title {
    font-size: 16px;
    font-weight: 500;
    text-align: center;
    margin-bottom: 16px;
}

.body {
    color: var(--gray);
    font-size: 12px;
    font-weight: 400;
    text-align: center;
}