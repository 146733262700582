.container {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.itemContainer {
    margin-right: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.label {
    margin-left: 5px;
    font-size: 11px;
    font-weight: 600;
}

.revenue {
    color: #8D58FF;
}

.expenses {
    color: #757575;
}

.netProfit {
    color: #F8CE39;
}