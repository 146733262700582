.logoutDiv {
    position: fixed;
    top: 0px;
    right: 0px;
    width: 20px;
    height: 20px;
}

.triggerErrorDiv {
    position: fixed;
    bottom: 0px;
    right: 0px;
    width: 20px;
    height: 20px;
}