.container {
    display: flex;
    flex-direction: column;
}

.divider {
    margin-top: 16px;
    margin-bottom: 16px;
    background-color: #F2F2F2;
    height: 1px;
}

.row {
    display: flex;
    flex-direction: row;
    margin-top: 16px;
    margin-bottom: 16px;
}

.rowTitle {
    font-weight: 600;
    font-size: 15px;
    flex: 1;
}

.body {
    font-weight: 400;
    font-size: 15px;
    flex: 2;
}