.sideBar {
    background-color: #698898;
}

.helpText {
    color: white;
}

.currentTab {
    background-color: #E8E8DD;
}

.tabText {
    color: white;
}

.currentTabText {
    color: black
}