.container {
    padding: 60px;
}

.sectionTitleContainer {
    margin-bottom: 20px;
}

.subtitle {
    font-weight: 300;
    font-size: 15px;
    margin-bottom: 30px;
}

.alertContainer {
    margin-bottom: 30px;
}

.saveAndContinueButton {
    margin-top: 30px;
}