.container {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.circle {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    margin-right: 10px;
}

.assetClass {
    font-weight: 600;
}