.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    z-index: 1;
    top: 50px;
    left: 0px;
    padding: 15px;
    box-shadow: 0px 2px 8px 0px #0000001F;
    border-radius: 12px;
    background: #FFFFFF;
}

.periodsContainer {
    margin-top: 20px;
}

.pickerContainer {
    margin-top: 10px;
}

