.header {
    margin-top: 20px;
    margin-bottom: 20px;
    font-size: 20px;
    font-weight: 500;
}

.subheading {
    margin-top: 10px;
    margin-bottom: 10px;
    font-size: 18px;
    font-weight: 300;
    color: #77786A;
}

.text {
    font-family: -apple-system, BlinkMacSystemFont, sans-serif;
    font-size: 14px;
    font-weight: 300;
    color: #000000;
    text-align: left;
}