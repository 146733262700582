.container {
    display: flex;
    flex-direction: row;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 5px;
    padding-right: 5px;
    background-color: #FAFAFA;
    border-radius: 12px;
}

.periodContainer {
    margin-left: 5px;
    margin-right: 5px;
}