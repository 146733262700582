.container {
    height: 100%;
    position: relative;
}

.sectionContainer {
    margin-bottom: 20px;
}

.overlay {
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(255, 255, 255, 0.5);
    backdrop-filter: blur(7px);
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.cardContainer {
    width: 80%;
}

.pageTitleContainer {
    margin-bottom: 20px;
}

.subtitle {
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 40px;
}

.videoContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-bottom: 20px;
}

.video {
    width: 90%;
    height: 40vh;
}

.buttonContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
}