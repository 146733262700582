.container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.logo {
    height: 20px;
}

.separator {
    color: #757575;
    font-size: 15px;
    font-weight: 500;
}