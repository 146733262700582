.container {
    padding: 60px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.pageTitleContainer {
    margin-bottom: 20px;
}

.subtitle {
    font-weight: 300;
    font-size: 15px;
}

.noCheckingAccountsContainer {
    margin-top: 30px;
    margin-bottom: 30px;
}

.buttonContainer {
    margin-top: 20px;
}