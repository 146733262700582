.container {
    display: flex;
    flex-direction: column;
}

.graphic {
    height: 30vh;
}

.pageContainer {
    padding: 60px;
}

.tableContainer {
    margin-bottom: 60px;
}