.container {
    display: flex;
    flex-direction: column;
    padding: 5px;
    background-color: #FAFAFA;
    border-radius: 12px;
}

.row {
    display: flex;
    flex-direction: row;
    margin-top: 5px;
    margin-bottom: 5px;
}

.quarterButtonContainer {
    margin-left: 5px;
    margin-right: 5px;
}