.container {
    padding: 60px;
    background-color: #E5E7F2;
    display: flex;
    flex-direction: row;
}

.imageContainer {
    flex: 3;
}

.image {
    width: 100%;
}

.spacer {
    flex: 1;
}

.content {
    flex: 6;
    display: flex;
    flex-direction: column;
}

.sectionTitleContainer {
    margin-bottom: 24px;
}
  
.stepsContainer {
    display: flex;
    flex-direction: column;
    gap: 10px;
}
  
.step {
    display: flex;
    align-items: center;
}
  
.circle {
    width: 27px;
    height: 27px;
    border-radius: 50%;
    background-color: white;
    color: var(--blue-purple);
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    font-size: 12px;
    margin-right: 15px;
    flex-shrink: 0;
}
  
.stepText {
    font-weight: 400;
    font-size: 16px;
}

.buttonsContainer {
    margin-top: 30px;
    width: 80%;
}


