.container {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.separator {
    margin-left: 10px;
    margin-right: 10px;
    font-size: 24px; 
    font-weight: 300;
    cursor: default;
}

.shortcutsButtonContainer {
    margin-left: 10px;
}