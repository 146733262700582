.container {
    display: flex;
    flex-direction: column;
    background-color: #F2F2F2;
    padding: 40px;
    font-weight: 400;
    font-size: 12px;
}

.bold {
    font-weight: 600;
}
