.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 60px;
}

.pageTitleContainer {
    margin-bottom: 30px;
}

.row {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-bottom: 30px;
}

.capitalProductContainer {
    margin-right: 30px;
}