.container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    max-height: 85vh;
    overflow: auto;
}

.pageTitleContainer {
    margin-bottom: 50px;
}

.sectionTitleContainer {
    margin-bottom: 30px;
}

.drawsTableContainer {
    margin-bottom: 50px;
}

.repaymentsTableContainer {
    margin-bottom: 20px;
}