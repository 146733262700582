.container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.iframeContainer {
    width: 100%;
    height: 1100px;
    margin-top: 20px;
    margin-bottom: 30px;
}

.checkboxContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.buttonContainer {
    margin-top: 20px;
}