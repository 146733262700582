.container {
    padding: 60px;
}

.endButtonContainer {
    position: fixed;
    bottom: 20px;
    left: 50%;
}

.emailReminder {
    background-color: white;
    border: 2px solid black;
    border-radius: 4px;
    position: fixed;
    top: 0;
    right: 0;
    margin-top: 20px; /* Optional: Add some margin from the top */
    margin-right: 20px; /* Optional: Add some margin from the right */
    padding: 10px; /* Add internal padding */
    width: 60%; /* Set the width */
}