.container {
    padding: 10px;
    box-shadow: 0px 2px 8px 0px #0000001F;
    border-radius: 12px;
    background: #FFFFFF;
    cursor: pointer;
    width: 80px;
    font-size: 14px;
    font-weight: 500;
    text-align: center;
}

.selected {
    color: var(--blue-purple);
}