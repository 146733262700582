.container {
    position: relative;
    display: inline-block;
}

.labelContainer {
    font-size: 13px;
    font-weight: 600;
    margin-bottom: 5px;
    color: #757575;
}

.left {
    text-align: left;
}

.right {
    text-align: right;
}