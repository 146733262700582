.container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    padding-top: 20px;
    padding-bottom: 20px;
    background-color: #F2F2F2;
}

.label {
    font-size: 16px;
    font-weight: 400;
    color: #757575;
}