.container {
    display: flex;
    flex-direction: column;
    padding: 10px;
}

.row {
    height: 30px;
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
}

.text {
    font-size: 14px;
    font-weight: 400;
    color: white;
}

.icon {
    width: 15px;
    margin-right: 5px;
}