.container {
    display: flex;
    flex-direction: column;
    position: absolute;
    z-index: 1;
    top: 65px;
    box-shadow: 0px 2px 8px 0px #0000001F;
    border-radius: 5px;
    background: #FFFFFF;
    padding: 10px;
}


.left {
    left: 0;
}

.right {
    right: 0;
}

.optionContainer {
    cursor: pointer;
    padding: 10px;
    white-space: nowrap;
    font-size: 14px;
    font-weight: 400;
}

.optionContainer:hover {
    background-color: #f0f0f0;;
}

.selected {
    background-color: #E5F5FD;
}

.optionContainer.selected:hover {
    background-color: #EBF3F7;
}

