.container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.pageTitleContainer {
    padding-top: 60px;
    padding-left: 60px;
    padding-right: 60px;
}

.realizedSavingsContainer {
    margin-top: 30px;
    padding-left: 60px;
    padding-right: 60px;
}

.savingsCallContainer {
    margin-top: 60px;
}

.savingsCardsContainer {
    margin-top: 60px;
    padding-left: 60px;
    padding-right: 60px;
    margin-bottom: 30px;
}