.container {
    background-color: #EAE5F2;
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    padding: 25px;
}

.image {
    margin-bottom: 20px;
}

.title {
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 10px;
}

.title sup {
    font-size: 10px;
    font-weight: 400;
}

.body {
    color: var(--gray);
    font-size: 12px;
    font-weight: 400;
}

