.table {
    border-collapse: collapse;
    width: 100%;
}

.table tr {
    border-bottom: 1px solid #F2F2F2;
}

.table td, .table th {
    padding-top: 15px;
    padding-bottom: 15px;
    padding-right: 15px;
    text-align: left;
}

.headerContainer {
    color: #757575;
    font-size: 12px;
    font-weight: 600;
}

.cellContainer {
    font-size: 12px;
    font-weight: 400;
}