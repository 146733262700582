.container {
    display: flex;
    flex-direction: row;
    padding: 25px 34px 25px 34px;
    border: 1px solid #000000;
    border-radius: 12px;
    height: 22vh;
}

.iconContainer {
    margin-right: 48px;
    width: 28px;
}

.content {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.title {
    font-weight: 500;
    font-size: 20px;
}

.body {
    font-weight: 400;
    font-size: 14px;
}