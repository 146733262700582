.container {
    display: flex;
    flex-direction: column;
}

.contentContainer {
    box-shadow: 0px 2px 8px 0px #0000001f;
    padding: 24px;
    background: #ffffff;
    border-radius: 12px;
}

.contentContainerWithTab {
    border-radius: 12px 0px 12px 12px;
}

.tab {
    align-self: flex-end;
    border-radius: 12px 12px 0px 0px;
    font-size: 14px;
    font-weight: 600;
    padding: 8px;
}