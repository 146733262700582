.container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100vh;
    width: 100vw;
}

.header {
    text-align: center;
}

.subheading {
    text-align: center;
}