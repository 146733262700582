.container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    cursor: pointer;
    box-shadow: 0px 2px 8px 0px #0000001F;
    border-radius: 12px;
    background: #FFFFFF;
    min-width: 150px;
}

.optionLabel {
    margin-right: 25px;
    font-size: 16px;
    font-weight: 400;
}

.chevronsUpDownIcon {
    width: 7px;
}